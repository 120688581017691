//------------------------------------for-local-----------------------------------*/

// export const Baseurl = 'http://localhost:3101/';

//------------------------------------for-staging-----------------------------------*/

// export const Baseurl ='http://3.93.226.10:3101/';
// export const storageUrl = 'https://knitpro-app-assets.s3.ap-south-1.amazonaws.com/'

//------------------------------------for-live------------------------------------*/
export const Baseurl =process.env.React_App_BASE_URL;
export const storageUrl =process.env.React_App_STORAGE_URL;
