import React, { Component } from 'react'
import { getAPI, putAPI ,deleteAPI} from '../utils/api';
import { Link } from 'react-router-dom';
import moment from 'moment';


export default class Userreview extends Component {

    state={
        reviews:[],
        isLoading:false,
        isApproved:true
    }
    componentDidMount(){
        this.setState({isLoading:true});
        this.getReviews();
    }
    componentDidUpdate(prevProps){
        if (this.props.language !== prevProps.language) {
            this.setState({isLoading:true});
            this.getReviews();       
        }
    }
    getReviews=()=>{
        const url='review/reviews/'+this.props.langId+`?isApproved=${this.state.isApproved}`;
        // this.setState({isLoading:true});
        getAPI(url).then(res=>{
            const {status,data}=res.data;
           if(status===1){
               this.setState({reviews:data},()=>{
                   this.setState({isLoading:false});
               })
           }else{
               this.setState({isLoading:false,reviews:[]})
           }
        }).catch(err=>{
            console.log(err);
        })
    }
    handleClick=(bool)=>{
        if(bool===1){
        this.setState({isApproved:true},()=>{
            this.getReviews();
        });
        }else{
        this.setState({isApproved:false},()=>{
             this.getReviews();
        });
        }
    }
    deleteReview=(uid)=>{
        deleteAPI(`review/review/${uid}`,{}).then(res=>{
            const {status}=res.data;
            if(status==1){
                this.getReviews();
            }
        }).catch(err=>{
            console.log(err);
        })
    }
    handleApprove=(uid)=>{
        const url=`review/review/${uid}?isApproved=${!this.state.isApproved}`
        const data={};
        putAPI(url,data).then(res=>{
            const {status}=res.data;
            if(status===1){
                this.getReviews();
            }
        }).catch(err=>{
            console.log(err);
        })
    }
    render() {
        const {reviews,isLoading,isApproved}=this.state;
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title">Product Reviews</h1>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                   <div className="card-header">
                                        <h4 className="card-header-title">All Reviews </h4>
                                        <div className="nav btn-group">
                                            <button className={isApproved?'btn btn-lg btn-white active':'btn btn-lg btn-white'} onClick={e=>this.handleClick(1)}>
                                             Approved
                                            </button>
                                            <button className={isApproved?'btn btn-lg btn-white ':'btn btn-lg btn-white active'} onClick={e=>this.handleClick(0)}>
                                             Unapproved
                                            </button>
                                        </div>
                                    </div>
                                    <div className="table-responsive mb-0">
                                        <table className="table table-sm table-wrap card-table">
                                            <thead>
                                                <tr>

                                                    <th>NAME</th>
                                                    {/* <th>Email</th> */}
                                                    <th>review</th>
                                                    <th>Product</th>
                                                    <th>Country</th>
                                                    <th>Rating</th>
                                                    <th>date</th>
                                                    <th colSpan="2">status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading?
                                                 <tr>
                                                 <td colspan="6"> 
                                                     <div className="text-center">
                                                         <div class="spinner-border text-danger" role="status">
                                                             <span class="sr-only">Loading...</span>
                                                         </div>
                                                     </div>
                                                 </td>
                                             </tr>
                                                :reviews.map((item,idx)=>(
                                                    <tr key={idx}>
                                                    <td>{item.name}</td>
                                                    {/* <td>{item.email}</td> */}
                                                    <td>
                                                        <div className="user-review position-relative hover">
                                                        <p className="review-content">{item.review}</p>
                                                        {item.review.length>50?<li className="review-tooltip">
                                                            <p className="mb-0">{item.review}</p>
                                                      
                                                        </li>:''}
                                                        {/* <li className="review-tooltip">
                                                            <p className="mb-0">{item.review}</p>
                                                      
                                                        </li> */}
                                                        </div>
                                                       
                                                        </td>
                                                        <td>{item.tagline}</td>
                                                        <td>{item.country?item.country:'---'}</td>
                                                    <td>
                                                        <span className={`star-icon ${item.rating >= 1 ? 'filled':  ''}`}>★</span>
                                                        <span className={`star-icon ${item.rating >= 2 ? 'filled':  ''}`}>★</span>
                                                        <span className={`star-icon ${item.rating >= 3 ? 'filled':  ''}`}>★</span>
                                                        <span className={`star-icon ${item.rating >= 4 ? 'filled':  ''}`}>★</span>
                                                        <span className={`star-icon ${item.rating >= 5 ? 'filled':  ''}`}>★</span>
                                                    </td>
                                                     <td>{moment(item.createdAt).format('MMMM Do YYYY')}</td>
                                                    <td>{item.isApproved?<span className="badge badge-soft-success">Approved</span>:<span className="badge badge-soft-danger">UnApproved</span>}</td>
                                                    <td className="text-right">
                                                       <div className="dropdown">
                                                            <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fe fe-more-vertical" />
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                            <a href="#!" className="dropdown-item" onClick={e=>this.handleApprove(item.uid)}>
                                                               {isApproved?'UnApprove':'Approve'}
                                                            </a>
                                                            <a href="#!" className="dropdown-item" onClick={e=>this.deleteReview(item.uid)}>
                                                               Delete
                                                            </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>


                                                ))}
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}