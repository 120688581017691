import React, { Component } from 'react'
import { NavLink,withRouter } from "react-router-dom";

class NavBar extends Component {

  componentDidMount(){
    document.getElementById('ii').className = 'nav-link'
  }
  handleLogout=()=>{
    localStorage.removeItem("userToken");
    // console.log(this.props);
    this.props.history.push('/login');
    window.location.reload();
    // console.log("done");
  }
  render() {
    return (
      <React.Fragment>
        <nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-dark navbar-vibrant" id="sidebar">
          <div className="container-fluid">
            {/* Toggler */}
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#sidebarCollapse"
              aria-controls="sidebarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            {/* Brand */}
            <a className="navbar-brand" href="#">
              <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} className="navbar-brand-img mx-auto" alt="..." />
            </a>
           <div className="collapse navbar-collapse" id="sidebarCollapse">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#sidebarDashboards"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i className="fe fe-home" /> Website
                   </a>
                  <div className="collapse " id="sidebarDashboards">
                    <ul className="nav nav-sm flex-column">

                      <li className="nav-item">
                        <NavLink to="/#" className="nav-link ">Home</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/about_us" className="nav-link ">About Us</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/social_contribution" className="nav-link ">Social Contribution</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/our_people" className="nav-link ">Our People</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/sustainability" className="nav-link ">Sustainability</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/infrastructure" className="nav-link ">Infrastructure</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/resources" className="nav-link ">Resources</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/privacy_policy" className="nav-link ">Privacy Policy</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/catalog" className="nav-link ">Catalogue</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/terms" className="nav-link ">Terms</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/store" className="nav-link ">Store Locator</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/faq" className="nav-link ">FAQ</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/catalogue-download" className="nav-link ">Catalogue Download</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/productreplacement" className="nav-link ">Product Replacement</NavLink>
                      </li>
                      
                      <li className="nav-item">
                        <NavLink to="/knitting-needles" className="nav-link ">Knitting Needles</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/crochet" className="nav-link ">Crochet</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/bags-cases" className="nav-link ">Bags Cases</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/knitting-crochet-sets" className="nav-link ">Knitting Crochet Sets</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/knitting-crochet-accessories" className="nav-link ">Knitting Crochet Accessories</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/whats-new" className="nav-link ">What's New</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/web-urls" className="nav-link ">Web URL's</NavLink>
                      </li>
                      {/* <li className="nav-item">
                        <NavLink to="/legal" className="nav-link ">Legal</NavLink>
                      </li> */}
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#Dashboards"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="Dashboards"
                  >
                    <i className="fe fe-file" /> Catalog
                  </a>
                  <div className="collapse " id="Dashboards">
                    <ul className="nav nav-sm flex-column">

                      <li className="nav-item">
                        <NavLink to="/categories" className="nav-link ">Categories </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/types" className="nav-link ">Types</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/materials" className="nav-link ">Materials</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/brands" className="nav-link ">Brands </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/products" className="nav-link ">Products</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/assets" className="nav-link ">Assets Management</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/brandDescription" className="nav-link ">Brand Description</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/materialDescription" className="nav-link ">Material Description</NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                <a
                    className="nav-link"
                    href="#blog"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="Dashboards"
                  >
                    <i className="fe fe-edit" />Blog</a>
                  <div className="collapse " id="blog">
                    <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                        <NavLink to="/blogCategory" className="nav-link ">Blog Categories</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/blog" className="nav-link ">Blogs </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <NavLink to="/testimonial" className="nav-link "> <i className="fe fe-copy"/>Testimonial</NavLink>
                </li>
                <li className="nav-item">
                <a
                    className="nav-link"
                    href="#Newsletter"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="Dashboards"
                  >
                    <i className="fe fe-mail" />Newsletter</a>
                  <div className="collapse " id="Newsletter">
                    <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <NavLink to="/Newsletter" className="nav-link ">Newsletters</NavLink>
                    </li>
                      <li className="nav-item">
                        <NavLink to="/newsletterusers" className="nav-link ">Subscribed Users</NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
               
                <li className="nav-item">
                  <NavLink to="/promo" className="nav-link "> <i className="fe fe-image" />Promo Banner</NavLink>
                </li>
               
                {/* <li className="nav-item">
                  <NavLink to="/mailer" className="nav-link "> <i className="fe fe-mail" />Mailer</NavLink>
                </li> */}
                <li className="nav-item">
                  <NavLink to="/style-script" className="nav-link "> <i className="fe fe-code" />Styles and Scripts</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/userreview" className="nav-link "> <i className="fe fe-message-square" />Product Reviews</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/upload" className="nav-link "> <i className="fe fe-upload" />Upload Files</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/redirection-url" className="nav-link ">  <i className="fe fe-copy"/>Redirection URL</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="#" id="ii" className="nav-link " data-target="#logout" data-toggle="modal"> <i className="fe fe-user-x" />Logout</NavLink>
                </li>
              </ul>

              <div className="mt-auto" />
             
            </div>
            <div className="navbar-user d-md-block d-none" id="sidebarUser">
                <a href="#" className="d-block text-center navbar-brand pb-2">
                   <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  
                </a>
                 <p className="d-block text-white text-center mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro<br/>All Rights Reserved.</p>
             </div>
          </div>
        </nav>
        <div className="modal" id="logout" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                <div className="card-header">
                                    <h4 className="card-header-title text-center" id="exampleModalCenterTitle">Do you really want to Logout?</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                    <div className="modal-body">
                                        <div className="col-12 text-center">
                                            <button type="button" className="btn btn-secondary btn-lg mr-3"   data-dismiss="modal">  Cancel  </button>
                                            <button type="button" className="btn btn-primary btn-lg"  data-dismiss="modal" onClick={this.handleLogout} >  Logout  </button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(NavBar);