import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { getAPI, postAPI, putAPI } from '../../utils/api';


export default class Productreplacement extends Component {

    constructor(props) {
        super(props);
        this.state = {
          header:"",footer:"",topBanner:{header:{},footer:{}},isSubmitting:false,isLoading:true
          };
        this.modules = {
            toolbar: [
              [{ 'font': [] }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              ['bold', 'italic', 'underline'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              [{ 'align': [] }],
              [{ 'color': [] }, { 'background': [] }],
              ['link','image','video'],
              ['clean']
            ]
        };
    
        this.formats = [
            'font',
            'size',
            'bold', 'italic', 'underline',
            'list', 'bullet',
            'align',
            'color', 'background',
            'link','image','video'
          ];
    }
    componentDidMount(){
        this.setState({isLoading:true});
        this.getheaderandfooter();
    }
    componentDidUpdate(prevProps) {
        if (this.props.language !== prevProps.language) {
        //   this.setState({isLoading: true});
          this.componentDidMount();
        }
      }


    getheaderandfooter=()=>{
        getAPI(`template/getTemplate?menuId=16&type=topBanner&lang=${this.props.language}`)
        .then(res => {
          const {data, status} = res.data;
          console.log(data, status);
          this.setState({isLoading:false,topBanner:data[0],header:data[0]?.header,footer:data[0]?.footer})
        }).catch(err => {console.log(err)});
    }
    handleChangeEditor=(e,string)=>{
        if(string==='header'){
            this.setState({header:e})
        }else{
            this.setState({
                footer:e
            })
        }
    }
    handleSave=()=>{
        const {language} = this.props;
        const topBanner=this.state.topBanner;
        this.setState({isSubmitting:true});
        if(topBanner?.id){
        let newObj={ 
            id:topBanner?.id,
            header:{[language]:this.state.header},
            footer:{[language]:this.state.footer}
        }
        const data = {
            "type": 'topBanner',
            "menuId": 16,
            "templateData": newObj
          }
          putAPI('template/editTemplate', data).then(res => {
            console.log(res);
            // this.componentDidMount();
            this.setState({isSubmitting: false});
          }).catch(error => {
            console.log(error)
          })
        }
    }


    render(){
        const {header,footer,isSubmitting,isLoading}=this.state;
        return(
            <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        {/* <h6 className="header-pretitle"> </h6> */}
                                        <h1 className="header-title">Product Replacement</h1>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        {isLoading?'Loading...'
                        :
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                <label></label>
                               <ReactQuill theme="snow" modules={this.modules}
                                formats={this.formats}  onChange={e=>this.handleChangeEditor(e,'header')}
                                value={header}/>
                               
                                </div>
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                <label></label>
                               <ReactQuill theme="snow" modules={this.modules}
                                formats={this.formats}  onChange={e=>this.handleChangeEditor(e,'footer')}
                                value={footer}/>
                               
                                </div>
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                            <div className='form-group'>
                          <button type="button" className="btn btn-primary mr-auto" onClick={e=>this.handleSave()}>
                           {isSubmitting?'Saving..':'Save'}
                          </button>
                        </div>
                            </div>
                        </div>}
                    </div>
                    </div>
        )
    }
}